
    import store from '@/store'
    import UmslLeftMenu from '@/types/ums_leftmenu'
    import UmsService from '@/service/UmsService'
    import JSDPageCtrl from '@/components/PageCtrl.vue';

    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    const _ = require('lodash')

    export default {
        name: 'UmsSendList',
        components: {
            JSDPageCtrl,
        },
        data() {
            return {
                ui: {
                    detail: {               //수신인 레이어
                        is_view: false,
                        left: 0,
                        top: 0,
                    },

                    preview: {                  //미리보기
                        is_view: false,
                        left: 0,
                        top: 0,
                    },
                    error: {                    //발송오류
                        is_view: false,
                        left: 0,
                        top: 0,
                    },
                    delete: {                   //삭제
                        is_view: false,
                    },
                    is_check_all: false,        //전체 선택여부
                    check_list: [],             //선택리스트


                },
                api: {
                    //서버와의 통신관련 api
                    list: {                     //발송이력 리스트 api
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        STRT_DA: new Date().addMonth(-1).toStr('yyyy-MM-dd'),   //조회시작일
                        END_DA: new Date().toStr('yyyy-MM-dd'),                 //조회종료일
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SMT_TYPE: 0,            // 0:전체 1:폰발송 2:웹발송
                        SEND_STAT_CD: '',       //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패
                        CNT_PAGE: 10,           //페이지당 조회건수
                    },
                    detail_list: {              //발송이력 리스트 api
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        SEND_DA: '',            //발송일자
                        SEND_TM: '',            //발송시간
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SMT_TYPE: 0,            // 0:전체 1:폰발송 2:웹발송
                        SEND_STAT_CD: '',       //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패
                        CNT_PAGE: 10000,        //페이지당 조회건수
                    },
                    resend: {
                        RESEND_TYPE: 1,         //재발송타입 1:발송이력 2:발송예약
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SMT_TYPE: 0,            //0:전체 1:폰발송 2:웹발송
                        SEND_STAT_CD: '',       //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패
                        SEND_TYPE: '',          //타입    AUMS:템플릿 SMS LMS MMS SMT
                        SEND_DATA: '',          //JSON
                    },
                    page_cnt: [10, 30, 50, 70, 100, 150, 300],        //페이징처리 건수배열
                    delete: {                   //발송이력 삭제 api

                    },
                },
                arr: {
                    send_list: [],              //발송이력 전체 리스트
                    detail_list: [],             //발송이력 상세 리스트
                    resend_list: [],            //재발송용리스트
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },

            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('발송이력');
                this.$setMainBtn('+ 문자 발송')
                this.$setMainBtnCallback(() => {
                    this.$movePage('/app/ums')
                });

                //좌측메뉴 제목
                this.$setSideTitle('문자 발송');

                //좌측 메뉴 구성
                this.$setSideBtns(UmslLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자')
                    }
                });

                this.$nextTick(() => {

                    //연락처 상세 페이지에서 넘어온 값이 있으면 처리한다.
                    if (sessionStorage.hasOwnProperty('custview_data')) {
                        let data = JSON.parse(sessionStorage.getItem('custview_data'))

                        let strt_da = data.STRT_DA.toDate('yyyyMMdd')
                        let end_da = data.END_DA.toDate('yyyyMMdd')

                        this.api.list.SEARCH_KEY = data.CUST_NM;
                        this.api.list.STRT_DA = strt_da.toStr('yyyy-MM-dd');
                        this.api.list.END_DA = end_da.toStr('yyyy-MM-dd');

                        sessionStorage.removeItem('custview_data');
                    }

                    this.getUmsSendList()
                })

            },
            getUmsSendList() {
                //발송리스트
                //console.log('getUmsSendList()');
                UmsService.ums_web_send_list(
                    this.page.val,
                    this.api.list,
                    (res) => {
                        this.ui.check_list = []                         //선택값 초기화
                        this.arr.send_list = res.data[0];				//일괄수정용 연락처 리스트 조회

                        this.page.maxCnt = this.arr.send_list.length > 0 ? this.arr.send_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                );
            },
            getUmsSendDetailList() {
                //발송상세리스트
                //console.log('getUmsSendDetailList');
                UmsService.ums_web_detail_list(
                    1,
                    this.api.detail_list,
                    (res) => {
                        this.arr.detail_list = res.data[0];				//일괄수정용 연락처 리스트 조회
                    }
                );
            },
            detail_view(event, obj) {
                //상세 조회

                if (Number(obj.CNT) == 1) {        //복수건만 창이뜬다
                    return
                }

                let pos = (<Element>event.target).getBoundingClientRect()//utils.abspos(event);

                this.ui.detail.left = pos.x + (pos.width / 5);

                this.api.detail_list.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.detail_list.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.detail_list.SMT_TYPE = this.api.list.SMT_TYPE
                this.api.detail_list.SEND_STAT_CD = String(obj.SEND_STAT_CD)
                this.api.detail_list.SEND_DA = String(obj.SEND_DA)
                this.api.detail_list.SEND_TM = String(obj.SEND_TM)

                this.getUmsSendDetailList()
                this.ui.detail.is_view = true

                setTimeout(() => {
                    const header = document.querySelector('.headArea')
                    const footer = document.querySelector('.footArea')
                    const layer = this.$refs.detailLayer.getBoundingClientRect()

                    if ((layer.height + pos.y) > (window.innerHeight + document.documentElement.scrollTop - (header.clientHeight + footer.clientHeight))) {
                        this.ui.detail.top = pos.y - layer.height + (pos.height / 2)
                    }
                    else {
                        this.ui.detail.top = pos.y + (pos.height / 2)
                    }
                }, 100)
            },
            preview_view(event, obj) {
                //상세 조회
                let pos = (<Element>event.target).getBoundingClientRect()

                this.ui.preview.left = pos.x + (pos.width / 5);

                this.api.detail_list.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.detail_list.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.detail_list.SMT_TYPE = this.api.list.SMT_TYPE
                this.api.detail_list.SEND_STAT_CD = this.api.list.SEND_STAT_CD
                this.api.detail_list.SEND_DA = String(obj.SEND_DA)
                this.api.detail_list.SEND_TM = String(obj.SEND_TM)

                this.getUmsSendDetailList()
                this.ui.preview.is_view = true

                setTimeout(() => {
                    const header = document.querySelector('.headArea')
                    const footer = document.querySelector('.footArea')
                    const layer = this.$refs.previewLayer.getBoundingClientRect()

                    if ((layer.height + pos.y) > (window.innerHeight + document.documentElement.scrollTop - (header.clientHeight + footer.clientHeight))) {
                        this.ui.preview.top = pos.y - layer.height + (pos.height / 2)
                    }
                    else {
                        this.ui.preview.top = pos.y + (pos.height / 2)
                    }
                }, 100)
            },
            error_view(event, obj) {
                let pos = (<Element>event.target).getBoundingClientRect()

                this.ui.error.left = pos.x + (pos.width / 5);

                this.api.detail_list.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.detail_list.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.detail_list.SMT_TYPE = this.api.list.SMT_TYPE
                this.api.detail_list.SEND_STAT_CD = String(obj.SEND_STAT_CD)
                this.api.detail_list.SEND_DA = String(obj.SEND_DA)
                this.api.detail_list.SEND_TM = String(obj.SEND_TM)

                this.getUmsSendDetailList()
                this.ui.error.is_view = true

                setTimeout(() => {
                    const header = document.querySelector('.headArea')
                    const footer = document.querySelector('.footArea')
                    const layer = this.$refs.errorLayer.getBoundingClientRect()

                    if ((layer.height + pos.y) > (window.innerHeight + document.documentElement.scrollTop - (header.clientHeight + footer.clientHeight))) {
                        this.ui.error.top = pos.y - layer.height + (pos.height / 2)
                    }
                    else {
                        this.ui.error.top = pos.y + (pos.height / 2)
                    }
                }, 100)
            },
            setData(type) {
                if (type == '1W') {
                    this.api.list.STRT_DA = new Date().addWeek(-1).toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().toStr('yyyy-MM-dd')
                }
                else if (type == '1M') {
                    this.api.list.STRT_DA = new Date().addMonth(-1).toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().toStr('yyyy-MM-dd')
                }
                else if (type == '3M') {
                    this.api.list.STRT_DA = new Date().addMonth(-3).toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().toStr('yyyy-MM-dd')
                }
                this.movePage(1)
            },
            check_all() {
                //전체 선택여부 처리
                if (this.ui.is_check_all) {
                    //전체 선택시
                    this.ui.check_list = []
                    for (var i = 0; i < this.arr.send_list.length; i++) {
                        this.ui.check_list.push(this.arr.send_list[i].SEQ_NO)
                    }
                }
                else {
                    //전체 미선택시 초기화
                    this.ui.check_list = []
                }
            },
            resend_recvList() {
                //수신인 목록에서 재발송 선택

                //console.log(this.arr.detail_list);

                if (this.arr.detail_list.length <= 0) {
                    alert('수신인 목록이 없습니다.')
                    return
                }

                let data = []
                let detail = this.arr.detail_list
                let idx = 0

                for (var i = 0; i < detail.length; i++) {
                    idx = data.findIndex(x => x.CUST_NO == detail[i].CUST_NO);

                    if (idx < 0) {
                        data.push({
                            CUST_NO: detail[i].CUST_NO,
                            CUST_NM: detail[i].CUST_NM,
                            PHONE_CNO: detail[i].RECV_HDPH_NO
                        });
                    }
                }

                //console.log(data);

                let cust_data: string = JSON.stringify(data);
                sessionStorage.setItem('custList', cust_data)
                //console.log(sessionStorage.getItem('custList'));
                //console.log('sessionStorage');
                //발송 페이지로 연결한다.

                this.$movePage('/app/ums')

            },  //수신인 목록에서 재발송 선택
            error_delete() {        //오류 연락처 삭제
                var mesg = '향후 수신 오류가 발생할 연락처를 휴지통으로 이동하시겠습니까?\n' +
                    '(휴지통으로 이동되는 연락처는 결번이가나 가입자 없음등의 오류입니다.)';

                if (confirm(mesg) === false) {
                    return;
                }

                UmsService.ums_delete(
                    (res) => {
                        mesg = '수신오류가 발생하는 연락처가 휴지통으로 이동되었습니다.\n' +
                            '연락처의 휴지통 메뉴로 가셔서 휴지통비우기를 하시면 연락처와 발신이력도 모두 삭제됩니다.'
                        alert(mesg);
                        this.movePage(1);
                    }
                );
            },
            resend_ums() {
                //연락처 선택한것들 다시 발송처리
                //선택한 리스트의 목록을 구해 세션 스토리지에 넣어서 문자 발송 페이지로 연견한다.
                if (this.ui.check_list.length == 0) {
                    alert('발송이력중 다시 발송할 목록을 선택해 주세요.')
                    return
                }

                //console.log(this.ui.check_list);
                let send_data = []
                for (var i = 0; i < this.ui.check_list.length; i++) {
                    let tmp = this.arr.send_list.filter(x => x.SEQ_NO == this.ui.check_list[i])
                    //console.log(tmp);
                    send_data.push({
                        SEND_DA: tmp[0].SEND_DA,
                        SEND_TM: tmp[0].SEND_TM,
                    })
                }

                this.api.resend.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.resend.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD //'':전체 1:SMS 3:MMS(LMS + MMS)
                this.api.resend.SMT_TYPE = this.api.list.SMT_TYPE           //0:전체 1:폰발송 2:웹발송
                this.api.resend.SEND_STAT_CD = this.api.list.SEND_STAT_CD   //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패
                this.api.resend.SEND_TYPE = ''      //타입    AUMS:템플릿 SMS LMS MMS SMT
                this.api.resend.SEND_DATA = JSON.stringify(send_data)

                //console.log(this.api.resend);

                UmsService.ums_resend(
                    this.api.resend,
                    (res) => {
                        this.arr.resend_list = res.data[0];				//일괄수정용 연락처 리스트 조회

                        let cust_data: string = JSON.stringify(this.arr.resend_list);
                        sessionStorage.setItem('custList', cust_data)
                        //console.log(sessionStorage.getItem('custList'));
                        //console.log('sessionStorage');
                        //발송 페이지로 연결한다.
                        //this.$router.replace('/app/ums')
                        this.$movePage('/app/ums')
                    }
                );
            },
            movePage(page) {
                // page이동.검색시 초기값
                this.page.val = page
                this.getUmsSendList();
            },
        },

        mounted() {
            this.init()
        },
    }
